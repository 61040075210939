var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{staticClass:"flex-shrink-0",attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('img',{staticClass:"w-32",attrs:{"src":require("@/assets/images/payments/ecpay_logo.png"),"alt":"ecpay"}})],1),(_vm.is_API_loaded)?_c('div',{staticClass:"space-y-10"},[_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("金流參數設定")])]),_c('div',{staticClass:"rightBlock"},[_c('div',[_c('section',{staticClass:"grid grid-cols-1 gap-4"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("商店代號 *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"商店代號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.sid),callback:function ($$v) {_vm.$set(_vm.setting, "sid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("金流介接 HashKey *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"金流介接 HashKey","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.hashkey),callback:function ($$v) {_vm.$set(_vm.setting, "hashkey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.hashkey"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("金流介接 HashIV *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"金流介接 HashIV","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.hashiv),callback:function ($$v) {_vm.$set(_vm.setting, "hashiv", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.hashiv"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])]),_c('hr'),_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("收款方式")])]),_c('div',{staticClass:"rightBlock",staticStyle:{"padding":"16px 0"}},[_c('div',[_c('collapse-item',{staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox.creditCard),callback:function ($$v) {_vm.$set(_vm.checkbox, "creditCard", $$v)},expression:"checkbox.creditCard"}}),_c('p',[_vm._v("線上刷卡 (VISA, MasterCard, JCB)")])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-4"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的付款名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的付款名稱","vid":"1","rules":((_vm.checkbox.creditCard ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":"線上刷卡 (VISA, MasterCard, JCB)"},model:{value:(_vm.creditCard.title),callback:function ($$v) {_vm.$set(_vm.creditCard, "title", $$v)},expression:"creditCard.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款期限")])]),_c('div',[_c('vs-select',{model:{value:(_vm.creditCard.pday),callback:function ($$v) {_vm.$set(_vm.creditCard, "pday", $$v)},expression:"creditCard.pday"}},[_c('vs-option',{attrs:{"label":"1天","value":1}},[_vm._v("1天")]),_c('vs-option',{attrs:{"label":"3天","value":3}},[_vm._v("3天")]),_c('vs-option',{attrs:{"label":"7天","value":7}},[_vm._v("7天")]),_c('vs-option',{attrs:{"label":"14天","value":14}},[_vm._v("14天")]),_c('vs-option',{attrs:{"label":"30天","value":30}},[_vm._v("30天")]),_c('vs-option',{attrs:{"label":"60天","value":60}},[_vm._v("60天")])],1)],1)]),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"付款說明","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px"},model:{value:(_vm.creditCard.note),callback:function ($$v) {_vm.$set(_vm.creditCard, "note", $$v)},expression:"creditCard.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('collapse-item',{staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox.ATM),callback:function ($$v) {_vm.$set(_vm.checkbox, "ATM", $$v)},expression:"checkbox.ATM"}}),_c('p',[_vm._v("ATM 轉帳")])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-4"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的付款名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的付款名稱","vid":"2","rules":((_vm.checkbox.ATM ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":"ATM 轉帳"},model:{value:(_vm.ATM.title),callback:function ($$v) {_vm.$set(_vm.ATM, "title", $$v)},expression:"ATM.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款期限")])]),_c('div',[_c('vs-select',{model:{value:(_vm.ATM.pday),callback:function ($$v) {_vm.$set(_vm.ATM, "pday", $$v)},expression:"ATM.pday"}},[_c('vs-option',{attrs:{"label":"1天","value":1}},[_vm._v("1天")]),_c('vs-option',{attrs:{"label":"3天","value":3}},[_vm._v("3天")]),_c('vs-option',{attrs:{"label":"7天","value":7}},[_vm._v("7天")]),_c('vs-option',{attrs:{"label":"14天","value":14}},[_vm._v("14天")]),_c('vs-option',{attrs:{"label":"30天","value":30}},[_vm._v("30天")]),_c('vs-option',{attrs:{"label":"60天","value":60}},[_vm._v("60天")])],1)],1)]),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"付款說明","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px"},model:{value:(_vm.ATM.note),callback:function ($$v) {_vm.$set(_vm.ATM, "note", $$v)},expression:"ATM.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('collapse-item',{staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox.code1),callback:function ($$v) {_vm.$set(_vm.checkbox, "code1", $$v)},expression:"checkbox.code1"}}),_c('p',[_vm._v("超商代碼")])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-4"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的付款名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的付款名稱","vid":"4","rules":((_vm.checkbox.code1 ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":"超商代碼"},model:{value:(_vm.code1.title),callback:function ($$v) {_vm.$set(_vm.code1, "title", $$v)},expression:"code1.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款期限")])]),_c('div',[_c('vs-select',{model:{value:(_vm.code1.pday),callback:function ($$v) {_vm.$set(_vm.code1, "pday", $$v)},expression:"code1.pday"}},[_c('vs-option',{attrs:{"label":"1天","value":1}},[_vm._v("1天")]),_c('vs-option',{attrs:{"label":"3天","value":3}},[_vm._v("3天")]),_c('vs-option',{attrs:{"label":"7天","value":7}},[_vm._v("7天")]),_c('vs-option',{attrs:{"label":"14天","value":14}},[_vm._v("14天")]),_c('vs-option',{attrs:{"label":"30天","value":30}},[_vm._v("30天")]),_c('vs-option',{attrs:{"label":"60天","value":60}},[_vm._v("60天")])],1)],1)]),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"付款說明","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px"},model:{value:(_vm.code1.note),callback:function ($$v) {_vm.$set(_vm.code1, "note", $$v)},expression:"code1.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('collapse-item',{staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox.code2),callback:function ($$v) {_vm.$set(_vm.checkbox, "code2", $$v)},expression:"checkbox.code2"}}),_c('p',[_vm._v("超商條碼")])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-4"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的付款名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的付款名稱","vid":"5","rules":((_vm.checkbox.code2 ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":"超商條碼"},model:{value:(_vm.code2.title),callback:function ($$v) {_vm.$set(_vm.code2, "title", $$v)},expression:"code2.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款期限")])]),_c('div',[_c('vs-select',{model:{value:(_vm.code2.pday),callback:function ($$v) {_vm.$set(_vm.code2, "pday", $$v)},expression:"code2.pday"}},[_c('vs-option',{attrs:{"label":"1天","value":1}},[_vm._v("1天")]),_c('vs-option',{attrs:{"label":"3天","value":3}},[_vm._v("3天")]),_c('vs-option',{attrs:{"label":"7天","value":7}},[_vm._v("7天")]),_c('vs-option',{attrs:{"label":"14天","value":14}},[_vm._v("14天")]),_c('vs-option',{attrs:{"label":"30天","value":30}},[_vm._v("30天")]),_c('vs-option',{attrs:{"label":"60天","value":60}},[_vm._v("60天")])],1)],1)]),_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("付款說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"付款說明","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px"},model:{value:(_vm.code2.note),callback:function ($$v) {_vm.$set(_vm.code2, "note", $$v)},expression:"code2.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)])]),_c('hr'),_c('div',{staticClass:"flex items-center justify-between"},[_c('router-link',{attrs:{"to":{ name: 'store_payments' }}},[_c('vs-button',{attrs:{"border":"","size":"large"}},[_vm._v("取消 ")])],1),_c('vs-button',{ref:"saveBtn",attrs:{"disabled":invalid,"size":"large"},on:{"click":function($event){return _vm.saveEdit(invalid)}}},[_vm._v("儲存")])],1)]):_c('div',{staticClass:"space-y-4"},_vm._l((2),function(item,index){return _c('section',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('skeleton',{staticClass:"mt-4",attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}})],1),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('skeleton',{attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"100px"}})],1),_c('div',{staticClass:"flex items-start flex-col space-y-4"},_vm._l((3),function(item,index){return _c('skeleton',{key:index,attrs:{"width":"200px","height":"10px"}})}),1)])])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }